import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { buildMessage, catalog } from "../../utils/motivMessages";
import {
    AthenaEventStreamPublisher,
    useAthenaEventStreamPublisher,
    useAthenaEventStream,
} from "../../contexts/EventStreamContext";
import { Subscriber } from "@evidenceb/athena-events-tools";
import { toast } from "react-toastify";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import { useIntl, IntlShape } from "react-intl";
import { useFeatureFlags } from "@evidenceb/athena-common/modules/FeatureFlags";
import { AthenaEvent } from "@evidenceb/athena-event-storage-schemas";
import React from "react";

const isImmediateDuplicate = (
    signatureFunction: (athenaEvent: any) => string
) => {
    let previousSignature = "";
    return (athenaEvent: any) => {
        const signature = signatureFunction(athenaEvent);
        if (signature === previousSignature) {
            return true;
        }
        previousSignature = signature;
        return false;
    };
};

const getMotivatorSubscriber = (
    intl: IntlShape,
    athenaEventStreamPublisher: AthenaEventStreamPublisher
) => {
    const duplicateChecker = isImmediateDuplicate((athenaEvent) => {
        return athenaEvent.data.exerciseId + athenaEvent.data.messageType;
    });
    return {
        id: "toaster",
        athenaEventTypeFilter: "AIShowMessageEvent",
        async processAthenaEvent(
            athenaEvent: AthenaEvent<"ai", "AIShowMessageEvent">
        ) {
            // check that the event type is in catalog
            const type = athenaEvent.data.messageType;
            if (type in catalog) {
                if (duplicateChecker(athenaEvent)) return;
                let message;
                try {
                    message = buildMessage(
                        athenaEvent,
                        intl,
                        athenaEventStreamPublisher
                    );
                } catch (e) {
                    console.error(
                        `unsupported athena-event: ${JSON.stringify(
                            athenaEvent
                        )}`,
                        e
                    );
                    return;
                }
                const ico = catalog[type].ico;
                toast(message, {
                    toastId: athenaEvent.id,
                    containerId: "progress-notifier",
                    autoClose: type === "blockedMessage" ? false : 8000,
                    closeOnClick: false,
                    icon: ico
                        ? React.createElement(Icon, {
                              path: `motivationIcons/${ico}`,
                              size: "medium",
                          })
                        : null,
                    progressClassName: ico
                        ? `progress-${ico.replaceAll("_", "-")}`
                        : undefined,
                });
            }
        },
    };
};

export const useEventStreamEarlySubscribers = () => {
    const flags = useFeatureFlags();
    const intl = useIntl();
    const athenaEventStream = useAthenaEventStream();
    const athenaEventStreamPublisher = useAthenaEventStreamPublisher();
    useEffect(() => {
        if (!athenaEventStream) {
            return;
        }
        if (flags.motivationalMessages) {
            const motivator = getMotivatorSubscriber(
                intl,
                athenaEventStreamPublisher
            );
            athenaEventStream.registerSubscriber(motivator);
            return () => {
                athenaEventStream.unregisterSubscriber(motivator.id);
            };
        }
    }, [athenaEventStream, intl, flags, athenaEventStreamPublisher]);
    useEffect(() => {
        if (!athenaEventStream) {
            return;
        }
        const sentryBreadcrumbSubscriber: Subscriber = {
            id: "sentryBreadcrumbSubscriber",
            async processAthenaEvent(athenaEvent: any) {
                Sentry.addBreadcrumb({
                    category: "athenaEvent",
                    message: `Received AthenaEvent: ${JSON.stringify(
                        athenaEvent
                    )}`,
                    level: Sentry.Severity.Info,
                });
            },
        };
        athenaEventStream.registerSubscriber(sentryBreadcrumbSubscriber);
        return () => {
            athenaEventStream.unregisterSubscriber(
                sentryBreadcrumbSubscriber.id
            );
        };
    }, [athenaEventStream]);
};
