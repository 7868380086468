import { IntlShape, defineMessages } from "react-intl";
import { workmodesMessages } from "../pages/Workmodes/workmodesMessages";
import { WorkModeType } from "../interfaces/Config";
import { Props as LoaderProps } from "../components/Loader/Loader";

/**
 * Common i18n messages for the app
 */
export const commonMessages = defineMessages({
    other: {
        id: "other",
        defaultMessage: "other",
    },
    print: {
        id: "print",
        defaultMessage: "Print",
    },
    back: {
        id: "back",
        defaultMessage: "Back",
    },
    colon: {
        id: "misc-colon",
        defaultMessage: ":",
    },
    cancel: {
        id: "cancel",
        defaultMessage: "Cancel",
    },
    modify: {
        id: "modify",
        defaultMessage: "Modify",
    },
    add: {
        id: "add",
        defaultMessage: "Add",
    },
    create: {
        id: "create",
        defaultMessage: "Create",
    },
    share: {
        id: "share",
        defaultMessage: "Share",
    },
    see: {
        id: "see",
        defaultMessage: "See",
    },
    play: {
        id: "play",
        defaultMessage: "Play",
    },
    submit: {
        id: "submit",
        defaultMessage: "Submit",
    },
    confirm: {
        id: "confirm",
        defaultMessage: "Confirm",
    },
    email: {
        id: "email",
        defaultMessage: "Email",
    },
    error: {
        id: "error",
        defaultMessage: "Error",
    },
    genericErrorMessage: {
        id: "genericErrorMessage",
        defaultMessage: "An error occurred. Please try again later.",
    },
    password: {
        id: "password",
        defaultMessage: "Password",
    },
    "#": {
        id: "#",
        defaultMessage: "#",
    },
    correct: {
        id: "correct",
        defaultMessage: "correct",
    },
    incorrect: {
        id: "incorrect",
        defaultMessage: "incorrect",
    },

    exercise: {
        id: "hierarchy-exercise-full",
        defaultMessage: "Exercise",
    },
    exercises: {
        id: "dashboard-studentProgression-exercises",
        defaultMessage: "Exercises",
    },
    activity: {
        id: "hierarchy-activity-full",
        defaultMessage: "activity",
    },
    activityShort: { id: "hierarchy-activity-short", defaultMessage: "act" },
    objective: {
        id: "hierarchy-objective-full",
        defaultMessage: "objective",
    },
    objectiveShort: {
        id: "hierarchy-objective-short",
        defaultMessage: "obj",
    },
    objectives: {
        id: "dashboard-common-objectives",
        defaultMessage: "Objectives",
    },
    module: {
        id: "hierarchy-module-full",
        defaultMessage: "module",
    },

    goHome: {
        id: "goBackHome",
        defaultMessage: "Go back to the homepage",
    },
    goBack: {
        id: "goBack",
        defaultMessage: "Go back",
    },
    and: {
        id: "and",
        defaultMessage: "and",
    },
    home: {
        id: "homepage",
        defaultMessage: "Home",
    },
    previous: {
        id: "previous",
        defaultMessage: "Previous",
    },
    next: {
        id: "next",
        defaultMessage: "Next",
    },
    start: {
        id: "start",
        defaultMessage: "Start",
    },
    finish: {
        id: "finish",
        defaultMessage: "Finish",
    },
    modules: {
        id: "modules",
        defaultMessage: "Modules",
    },
    student: {
        id: "student",
        defaultMessage: "student",
    },
    students: {
        id: "students",
        defaultMessage: "students",
    },
    teacher: {
        id: "teacher",
        defaultMessage: "teacher",
    },
    open: {
        id: "open",
        defaultMessage: "Open",
    },
    all: {
        id: "All",
        defaultMessage: "All",
    },
    select: {
        id: "select",
        defaultMessage: "Select",
    },
    classrooms: {
        id: "dashboard-common-classes",
        defaultMessage: "Classes",
    },
    subject: {
        id: "subject",
        defaultMessage: "Subject",
    },
    classroomCode: {
        id: "classDetails-code",
        defaultMessage: "Class code",
    },
    lastName: {
        id: "common-lastName",
        defaultMessage: "Last name",
    },
    firstName: {
        id: "common-firstName",
        defaultMessage: "First name",
    },
    studentCode: {
        id: "common-studentCode",
        defaultMessage: "Student code",
    },
    close: {
        id: "close",
        defaultMessage: "Close",
    },
    required: {
        id: "required",
        defaultMessage: "required",
    },
    loading: {
        id: "loader",
        defaultMessage: "Loading",
    },
    loadingConcluded: {
        id: "loadingConcluded",
        defaultMessage: "Loading has concluded",
    },
    loadingLockStatus: {
        id: "loadingLockStatus",
        defaultMessage: "Loading modules status",
    },
    loadingLockStatusConcluded: {
        id: "loadingLockStatusConcluded",
        defaultMessage: "Modules status loaded",
    },
    goToStudentDetail: {
        id: "user-list-goToStudentDetail",
        defaultMessage: "Go to {student} page",
    },
});

export const lockStatusLoadingMessages = (
    intl: IntlShape
): Pick<LoaderProps, "loadedMessage" | "loadingMessage"> => ({
    loadingMessage: intl.formatMessage(commonMessages.loadingLockStatus),
    loadedMessage: intl.formatMessage(
        commonMessages.loadingLockStatusConcluded
    ),
});

export const pageTitles = {
    ...defineMessages({
        home: {
            id: "pageTitle-home",
            defaultMessage: "Welcome",
        },
        homeNavigation: {
            id: "pageTitles-homeNavigation",
            defaultMessage: "Home",
        },

        prm: {
            id: "pageTitle-prm",
            defaultMessage: "Manage pedagogical resources",
        },

        work: {
            id: "workInProgressHome-work",
            defaultMessage: "Work",
        },

        standaloneAdaptiveTestResults: {
            id: "adaptive-test-results-title",
            defaultMessage: "Evaluation - my results",
        },
        standaloneAdaptiveTestModuleList: {
            id: "adaptive-test-dashboard-title",
            defaultMessage: "Evaluation",
        },
        standaloneAdaptiveTestPlayer: {
            id: "pageTitle-standaloneAdaptiveTest",
            defaultMessage: "Test",
        },

        studentWorkmodeSelection: {
            id: "workMode-title",
            defaultMessage: "My Work",
        },
        teacherWorkmodeSelection: {
            id: "teacherResources-title",
            defaultMessage: "My resources",
        },

        studentAssessment: {
            id: "dashboard-student-title",
            defaultMessage: "My assessment",
        },

        teacherDashboard: {
            id: "pageTitle-teacherDashboard",
            defaultMessage: "Dashboard",
        },
        revisionPlayer: {
            id: "pageTitle-revisionPlayer",
            defaultMessage: "Revision Player",
        },
        teacherDashboardClassroomProgression: {
            id: "dashboard-common-progression",
            defaultMessage: "Progression",
        },

        teacherDuosListTitle: {
            id: "pageTitle-studentDuosList",
            defaultMessage: "Duo",
        },
        teacherModuleDuos: {
            id: "pageTitle-studentModuleDuos",
            defaultMessage: "Duos - {item}",
        },
        studentDuosList: {
            id: "workmodes-duo-title",
            defaultMessage: "Duo",
        },

        teacherPlaylistCreate: {
            id: "pageTitle-TeacherPlaylistCreate",
            defaultMessage: "New playlist",
        },
        teacherPlaylistEdit: {
            id: "pageTitle-teacherPlaylistEdit",
            defaultMessage: "Playlist - Edit {playlist}",
        },
        teacherPlaylistEditAssignment: {
            id: "pageTitle-teacherPlaylistEditAssignment",
            defaultMessage: "Playlist {playlist} - Edit recipients",
        },
        teacherPlaylistsList: {
            id: "pageTitle-teacherPlaylistsList",
            defaultMessage: "Playlists",
        },
        studentPlaylistPlayer: {
            id: "pageTitle-studentPlaylistPlayer",
            defaultMessage: "Playlist {playlist}",
        },
        studentPlaylistsList: {
            id: "pageTitle-studentPlaylistsList",
            defaultMessage: "Playlists",
        },

        tutosList: {
            id: "pageTitle-tutosList",
            defaultMessage: "Tutos",
        },
        tutoPlayer: {
            id: "pageTitle-tutoPlayer",
            defaultMessage: "Tuto {tuto}",
        },

        teacherWorkshopPlayer: {
            id: "pageTitle-teacherWorkshopPlayer",
            defaultMessage: "Workshop {workshop}",
        },

        studentAuth: {
            id: "pageTitle-studentAuth",
            defaultMessage: "Student authentication",
        },
        teacherAuthNewPwd: {
            id: "authentication-pwd-newPwd",
            defaultMessage: "New Password",
        },
        teacherAuthCreateAccount: {
            id: "authentication-pwd-createAccountTitle",
            defaultMessage: "Create your {appName} account",
        },
        teacherAuthForgottenPwd: {
            id: "authentication-teacher-forgottenPwd",
            defaultMessage: "Forgotten Password",
        },
        teacherAuth: {
            id: "authentication-teacher-title",
            defaultMessage: "Teacher",
        },
        authLogoutConfirmation: {
            id: "pageTitle-logoutConfirmation",
            defaultMessage: "You are now logged out",
        },
        authSelectUserType: {
            id: "authentication-welcomeTitle",
            defaultMessage: "Log in to {clientName}",
        },

        teacherSoloAIPlayer: {
            id: "pageTitle-teacherSoloAI",
            defaultMessage: "Solo AI",
        },
        studentSoloAIPlayer: {
            id: "pageTitle-teacherSoloAI",
            defaultMessage: "Solo AI",
        },

        teacherSubjectChoiceWelcome: {
            id: "subjectChoice-welcomeTitle",
            defaultMessage: "Welcome on {client} !",
        },
        teacherSubjectChoice: {
            id: "subjectChoice-title",
            defaultMessage: "Choose a subject",
        },

        accountSettings: {
            id: "pageTitle-accountSettings",
            defaultMessage: "Account settings",
        },
    }),
    teacherSoloAIModuleList: workmodesMessages[WorkModeType.SoloAI],
    studentSoloAIModuleList: workmodesMessages[WorkModeType.SoloAI],
    teacherDashboardClassroomList: commonMessages.classrooms,
    tutosList: workmodesMessages[WorkModeType.Tuto],
    teacherWorkshopsList: workmodesMessages[WorkModeType.Workshop],
    studentWorkshopsList: workmodesMessages[WorkModeType.Workshop],
    authCustomError: commonMessages.error,
    error: commonMessages.error,
};
