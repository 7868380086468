export interface CustomColors {
    playerHeaderBg: string;
    playerHeaderTxt: string;
    playerHeaderBottomBorder: string;
    footerBg: string;
    footerTxt: string;
    headerTxt: string;
}

/**
 * Legacy theme is partially supported. Tokens are added as
 * css variables, but app style is moving away from using these
 * tokens. For new development, prefer current token, see Theme
 * type.
 **/
export interface LegacyTheme {
    colorPrimary: string;
    colorPrimaryLight: string;
    colorSecondary: string;
    colorSecondaryLight: string;
    colorCorrect: string;
    colorError: string;
    colorAlert: string;
    chatbot?: {
        backgroundColorCorrect: string;
        backgroundColorIncorrect: string;
        botBubbleColor: string;
        botBubbleColorCorrect: string;
        botBubbleColorFeedback: string;
        botBubbleColorIncorrect: string;
        disabledBg: string;
        disabledText: string;
        textCorrect: string;
        textIncorrect: string;
        userBubbleColorInteractive: string;
        userBubbleColorNonInteractive: string;
        validateBg: string;
    };
    /**
     * Colors that are only used if the customColors feature flag is set to true
     */
    customColors?: Partial<CustomColors>;
    contentPagesStyle: string;
}

/**
 * The type determines the default values for the css tokens.
 * Specified ThemeColors override these default values with a
 * custom value for the variation.
 * */
export type Theme = {
    /** @default "light" */
    type?: ThemeType;
} & Partial<ThemeColors>;

export enum ThemeType {
    Light = "light",
    Dark = "dark",
}

export const themeColors = [
    "primaryColor",

    "defaultBlack",
    "defaultWhite",

    "blockHeader",
    "blockFooter",
    "blockPage",
    "contentPage",
    "block",
    "blockBorder",

    "blockItem1Default",
    "blockItem1Selected",
    "blockItem1SelectedTeacher",
    "blockItem1SelectedSubject1",
    "blockItem1SelectedSubject2",

    "blockItem1BorderDefault",
    "blockItem1BorderSelected",

    "blockItem2Default",

    "textMainDefault",
    "textMainOff",
    "textMainDisabled",
    "textMainInverted",

    "textH1ContentPageDefault",

    "textHeaderDefault",
    "textFooterDefault",

    "textSubject1Default",
    "textSubject1Off",
    "textSubject1Disabled",

    "textSubject2Default",
    "textSubject2Off",
    "textSubject2Disabled",

    "elementGrey10",
    "elementGrey20",
    "elementGrey50",
    "elementGrey80",

    "elementEvidencebDefault",
    "elementEvidencebHover",
    "elementEvidencebDisabled",
    "elementEvidencebIdle",
    "elementEvidencebUnfocused",

    "elementTeacherDefault",
    "elementTeacherHover",
    "elementTeacherDisabled",
    "elementTeacherIdle",
    "elementTeacherUnfocused",

    "elementSubject1Default",
    "elementSubject1Hover",
    "elementSubject1Disabled",
    "elementSubject1Idle",
    "elementSubject1Unfocused",

    "elementSubject2Default",
    "elementSubject2Hover",
    "elementSubject2Disabled",
    "elementSubject2Idle",
    "elementSubject2Unfocused",

    "elementNavigationDefault",
    "elementNavigationHover",
    "elementNavigationDisabled",

    "elementButtonPrimaryDefault",
    "elementButtonPrimaryBorderDefault",
    "elementButtonPrimaryTextDefault",
    "elementButtonPrimaryHover",
    "elementButtonPrimaryBorderHover",
    "elementButtonPrimaryTextHover",

    "elementButtonSecondaryDefault",
    "elementButtonSecondaryBorderDefault",
    "elementButtonSecondaryTextDefault",
    "elementButtonSecondaryHover",
    "elementButtonSecondaryBorderHover",
    "elementButtonSecondaryTextHover",

    "elementButtonCustomHover",

    "elementTabPrimaryActiveTextDefault",
    "elementTabPrimaryActiveTextHover",

    "elementTabPrimaryActiveBorderDefault",

    "elementTabPrimaryInactiveDefault",
    "elementTabPrimaryInactiveHover",

    "elementTabPrimaryInactiveTextDefault",
    "elementTabPrimaryInactiveTextHover",

    "elementTabPrimaryInactiveBorderDefault",
    "elementTabPrimaryInactiveBorderHover",

    "elementBackgroundAvatar",

    "elementObjectiveBackgroundCompleted",
    "elementObjectiveBackgroundInProgress",
    "elementObjectiveBackgroundNotExplored",

    "alertCorrect",
    "alertNeutral",
    "alertFailure",

    "alertUp",
    "alertDown",

    "ressourcesFillSolo",
    "ressourcesFillDuo",
    "ressourcesFillPlaylist",
    "ressourcesFillTuto",
    "ressourcesFillWorkshop",

    "thumbnailMediaIllustrationFillNeutral",
    "thumbnailMediaIllustrationFillHover",

    "thumbnailMediaStrokeNeutral",
    "thumbnailMediaStrokeHover",
] as const;

type ObjectFromList<T extends ReadonlyArray<string>, V = string> = {
    [K in T extends ReadonlyArray<infer U> ? U : never]: V;
};

export type ThemeColors = ObjectFromList<typeof themeColors>;
