import {
    Activity,
    HierarchyIds,
    MinimalDataItem,
    Module,
    PartialExerciseResult,
    PerModule,
    VisibilityStatus,
} from "@evidenceb/gameplay-interfaces";
import {
    ActivityNotVisibleError,
    ActivityNotFoundError,
    ExerciseNotVisibleError,
    ExerciseNotFoundError,
    ModuleNotVisibleError,
    ModuleNotFoundError,
    ObjectiveNotVisibleError,
    ObjectiveNotFoundError,
    ItemNotFoundError,
} from "../errors";
import { Data, MinimalData, Objective } from "../interfaces/Data";
import { Page } from "../interfaces/Config";
import { ContentPage } from "../interfaces/ContentPage";
import {
    ClassroomAnalytics,
    ClassroomsClustering,
    ClusterInfosClustering,
    Dashboard,
    ModuleCluster,
} from "../interfaces/Dashboard";
import { Subject, UserType } from "../interfaces/User";
import { Exercise } from "@evidenceb/athena-common/interfaces/Exercise";
import { FeatureFlags } from "@evidenceb/athena-common/modules/FeatureFlags";
import { Hierarchy } from "../interfaces/Hierarchy";
import { Group, Student } from "../interfaces/Session";
import { ZPDESActivityDiagnosis } from "@evidenceb/ai-butler";
import { ResourceSeries } from "../components/Player/ResourceSeries/types";
import { getLearningItem } from "../components/Player/ResourceSeries/utils";
import { Statement } from "@xapi/xapi";
import { filterObjectKeys, mapObjectValues } from "./array-manipulations";
import { PLACEHOLDER_SUBJECT } from "../hooks/useActiveSubject";
import { MappingNode } from "../interfaces/MappingNode";
import { Playlist, PlaylistItem } from "../pages/Workmodes/Playlist/type";
import { mappingTreeToSubjects } from "./mapping";
import { Workshop } from "../pages/Workmodes/Workshop/types";
import { Tuto } from "../pages/Workmodes/Tuto/type";
import {
    CollectionResourcePath,
    ResourcePath,
} from "../pages/Workmodes/Playlist/Teacher/utils";

export const getUrl = (page: Page | ContentPage, userType: UserType) => {
    let url: string = "";
    switch (page.display.mode) {
        case "DEFAULT":
            url = page.display.url as string;
            break;
        case "USER_BASED":
            if (userType === UserType.Teacher) {
                url = page.display.url[0];
            }
            if (userType === UserType.Student) {
                url = page.display.url[1];
            }
            break;
        default:
            throw new Error("getUrl(): display.mode is not valid");
    }
    return url;
};

export function getModuleById<T extends Pick<MinimalData, "modules"> = Data>(
    id: string,
    data: T,
    visibleOnly = true
): T["modules"][0] {
    const module = data.modules.find((module) => module.id === id);
    if (!module) throw new ModuleNotFoundError(`Module ${id} was not found`);
    if (
        visibleOnly &&
        module.visibilityStatus !== VisibilityStatus.Visible &&
        module.visibilityStatus !== VisibilityStatus.Unavailable
    )
        throw new ModuleNotVisibleError(`Module ${id} is not visible`);
    return module;
}

export function getObjectiveById<
    T extends Pick<MinimalData, "objectives"> = Data
>(id: string, data: T, visibleOnly = true): T["objectives"][0] {
    const objective = data.objectives.find((objective) => objective.id === id);
    if (!objective)
        throw new ObjectiveNotFoundError(`Objective ${id} was not found`);
    if (
        visibleOnly &&
        objective.visibilityStatus !== VisibilityStatus.Visible &&
        objective.visibilityStatus !== VisibilityStatus.Unavailable
    )
        throw new ObjectiveNotVisibleError(`Objective ${id} is not visible`);
    return objective;
}

export function getActivityById<
    T extends Omit<MinimalData, "exercises"> = Data
>(id: string, data: T, visibleOnly = true): T["activities"][0] {
    const activity = data.activities.find((activity) => activity.id === id);
    if (!activity)
        throw new ActivityNotFoundError(`Activity ${id} was not found`);
    if (
        visibleOnly &&
        activity.visibilityStatus !== VisibilityStatus.Visible &&
        activity.visibilityStatus !== VisibilityStatus.Unavailable
    )
        throw new ActivityNotVisibleError(`Activity ${id} is not visible`);
    return activity;
}

export const getExerciseById = (
    id: string,
    exercises: Exercise[],
    visibleOnly = true
): Exercise => {
    let exercise = exercises.find((exercise) => exercise.id === id);
    if (!exercise)
        throw new ExerciseNotFoundError(`Exercise ${id} was not found`);
    if (
        visibleOnly &&
        exercise.visibilityStatus !== VisibilityStatus.Visible &&
        exercise.visibilityStatus !== VisibilityStatus.Unavailable
    )
        throw new ExerciseNotVisibleError(`Exercise ${id} is not visible`);

    return exercise;
};

/**
 * Returns a complete hierarchy given a set of ids for each level.
 * If the id is not given for a certain level, it defaults to the first item
 * of the superior level.
 */
export const getHierarchy = (
    data: Data,
    moduleId?: string,
    objectiveId?: string,
    activityId?: string
): Omit<Hierarchy, "exercise" | "isInitialTest"> => {
    if (
        (activityId && (!objectiveId || !moduleId)) ||
        (objectiveId && !moduleId)
    )
        throw new Error(
            "The superior id of a given level id should always be defined"
        );

    const module = moduleId ? getModuleById(moduleId, data) : data.modules[0];
    if (module.visibilityStatus !== VisibilityStatus.Visible)
        throw new ModuleNotVisibleError(`Module ${moduleId} is not visible`);

    if (objectiveId && !module.objectiveIds.includes(objectiveId))
        throw new ObjectiveNotFoundError(
            `Objective ${objectiveId} was not found`
        );
    const objective = objectiveId
        ? getObjectiveById(objectiveId, data)
        : getObjectiveById(module.objectiveIds[0], data);
    if (objective.visibilityStatus !== VisibilityStatus.Visible)
        throw new ObjectiveNotVisibleError(
            `Objective ${objectiveId} is not visible`
        );

    if (activityId && !objective.activityIds.includes(activityId))
        throw new ActivityNotFoundError(`Activity ${activityId} was not found`);
    const activity = activityId
        ? getActivityById(activityId, data)
        : getActivityById(objective.activityIds[0], data);
    if (activity.visibilityStatus !== VisibilityStatus.Visible)
        throw new ActivityNotVisibleError(
            `Activity ${activityId} is not visible`
        );

    return {
        module,
        objective,
        activity,
    };
};

export const getHierarchyFromHierarchyId = (
    hierarchyId: HierarchyIds,
    data: Data,
    exercises: Exercise[]
): Omit<Hierarchy, "isInitialTest"> => {
    return {
        module: getModuleById(hierarchyId.moduleId, data),
        objective: getObjectiveById(hierarchyId.objectiveId, data),
        activity: getActivityById(hierarchyId.activityId, data),
        exercise: getExerciseById(hierarchyId.exerciseId, exercises),
    };
};

/**
 * Retrieves all exercises that are included in an activity. Exercises are
 * returned in the order their ids are provided in the activity exercise list.
 */
export const getExercisesInActivity = (
    activity: Activity,
    exercises: Exercise[]
): Exercise[] => {
    return activity.exerciseIds
        .map((exerciseId) => getExerciseById(exerciseId, exercises, false))
        .filter(
            (exercise) => exercise.visibilityStatus === VisibilityStatus.Visible
        );
};

export function getActivitiesInModule<
    T extends Omit<MinimalData, "exercises"> = Data
>(module: T["modules"][0], data: T): T["activities"] {
    const objectives = module.objectiveIds
        .map((objectiveId) => getObjectiveById(objectiveId, data))
        .filter(
            (objective) =>
                objective.visibilityStatus === VisibilityStatus.Visible
        );
    return objectives
        .map((objective) => objective.activityIds)
        .flat()
        .map((activityId) => getActivityById(activityId, data))
        .filter(
            (activity) => activity.visibilityStatus === VisibilityStatus.Visible
        );
}

/**
 * Given a level of hierarchy, returns the immediately above level.
 * Only visible items in each level are taken into account.
 */
export const getNextHierarchyLevel = (
    data: Data,
    hierarchy: Omit<Hierarchy, "exercise" | "isInitialTest">,
    allowModuleChange: boolean = true
): Omit<Hierarchy, "exercise" | "isInitialTest"> | undefined => {
    const activityPool = getSublevelPool<Activity>(
        hierarchy.objective.activityIds,
        data.activities
    );
    const activityIndex = activityPool.findIndex(
        (availableActivity) => availableActivity.id === hierarchy.activity.id
    );
    if (activityIndex !== activityPool.length - 1)
        return {
            ...hierarchy,
            activity: getActivityById(activityPool[activityIndex + 1].id, data),
        };

    const objectivePool = getSublevelPool<Objective>(
        hierarchy.module.objectiveIds,
        data.objectives
    );
    const objectiveIndex = objectivePool.findIndex(
        (availableObjective) => availableObjective.id === hierarchy.objective.id
    );
    if (objectiveIndex !== objectivePool.length - 1) {
        const newObjective = getObjectiveById(
            objectivePool[objectiveIndex + 1].id,
            data
        );
        const activityPool = getSublevelPool<Activity>(
            newObjective.activityIds,
            data.activities
        );
        return {
            module: hierarchy.module,
            objective: newObjective,
            activity: activityPool[0],
        };
    }

    if (!allowModuleChange) return undefined;
    const modulePool = data.modules.filter(
        (module) => module.visibilityStatus === VisibilityStatus.Visible
    );
    const moduleIndex = modulePool.findIndex(
        (availableModule) => availableModule.id === hierarchy.module.id
    );
    if (moduleIndex !== modulePool.length - 1) {
        const newModule = modulePool[moduleIndex + 1];
        const objectivePool = getSublevelPool<Objective>(
            newModule.objectiveIds,
            data.objectives
        );
        const activityPool = getSublevelPool<Activity>(
            objectivePool[0].activityIds,
            data.activities
        );
        return {
            module: newModule,
            objective: objectivePool[0],
            activity: activityPool[0],
        };
    }

    return undefined;
};

/**
 * Returns all items of a sublevel of hierarchy that are visible and contained
 * in the given level.
 */
export function getSublevelPool<
    Sublevel extends { id: string; visibilityStatus: VisibilityStatus }
>(sublevelIds: string[], availableSublevelItems: Sublevel[]): Sublevel[] {
    return sublevelIds
        .map((sublevelId) => {
            const correspondingItem = availableSublevelItems.find(
                (item) => item.id === sublevelId
            );
            if (!correspondingItem) throw new ItemNotFoundError();
            return correspondingItem;
        })
        .filter((item) => item.visibilityStatus === VisibilityStatus.Visible);
}

export const getRandomExercise = (
    data: Data,
    moduleId?: string
): HierarchyIds => {
    const module = moduleId
        ? getModuleById(moduleId, data)
        : data.modules[Math.floor(Math.random() * data.modules.length)];
    const objective = getObjectiveById(
        module.objectiveIds[
            Math.floor(Math.random() * module.objectiveIds.length)
        ],
        data
    );
    const activity = getActivityById(
        objective.activityIds[
            Math.floor(Math.random() * objective.activityIds.length)
        ],
        data
    );
    return {
        moduleId: module.id,
        objectiveId: objective.id,
        activityId: activity.id,
        exerciseId:
            activity.exerciseIds[
                Math.floor(Math.random() * activity.exerciseIds.length)
            ],
    };
};

/**
 * Get a student's name from their ID
 */
export const getStudentName = (
    classrooms: ClassroomAnalytics[],
    classId: string,
    moduleId: string,
    studentId: string
): string => {
    const classroom = classrooms.find((classroom) => classroom.id === classId);
    if (!classroom) throw new Error("Classroom not found");
    const module = classroom.modulesList.find(
        (module) => module.id === moduleId
    );
    if (!module) throw new Error("Module not found");
    const student = module.students[studentId];
    if (!student) throw new Error("Student not found");
    return `${student.firstname} ${student.lastname}`;
};

/**
 * Returns the list of clusters from the clustering information of a module
 */
export const getClusters = (
    clustering: ModuleCluster
): ClusterInfosClustering[] => {
    // This is due to the weird shape of the infosClustering.clusters list
    return clustering.infosClustering.clusters
        .map((clustersObj) =>
            Object.keys(clustersObj).map((clusterId) => clustersObj[clusterId])
        )
        .flat();
};

/**
 * Returns the number of indentified groups in the clustering
 */
export const getIdentifiedGroupsNumber = (
    clustering: ClassroomsClustering,
    classroomId: string,
    moduleId: string,
    i18n: string
) => {
    return clustering &&
        typeof clustering[classroomId][moduleId] !== "undefined" &&
        typeof clustering[classroomId][moduleId].error === "undefined"
        ? `${
              getClusters(clustering[classroomId][moduleId] as ModuleCluster)
                  .length
          } ${i18n}`
        : `0 ${i18n}`;
};

/**
 * Generates a filter that adds or removes initial test objectives
 */

export const initialTestFilter = (
    initialTest: PerModule<any> | undefined,
    output: "notInitialTest" | "isInitialTest"
): ((objective: string | { id: string }) => boolean) => {
    let initialTestsIds = [] as string[];
    if (initialTest && Object.keys(initialTest).length > 0) {
        for (const id in initialTest) {
            if (initialTest[id].objectiveId) {
                initialTestsIds.push(initialTest[id].objectiveId);
            }
        }
    }
    return (objective) =>
        output === "isInitialTest"
            ? initialTestsIds.includes(
                  typeof objective === "string" ? objective : objective.id
              )
            : !initialTestsIds.includes(
                  typeof objective === "string" ? objective : objective.id
              );
};

/**
 * Return true/false depending if the pool of objectives or objective ids has an objective test
 */

export const hasObjectiveTest = (
    pool: MinimalDataItem[] | string[],
    initialTest: PerModule<any> | undefined
) => {
    let testObjective;

    if (typeof pool[0] === "string") {
        testObjective = (pool as string[]).find(
            initialTestFilter(initialTest, "isInitialTest")
        );
    } else {
        testObjective = (pool as MinimalDataItem[]).find(
            initialTestFilter(initialTest, "isInitialTest")
        );
    }

    if (testObjective) return true;
    return false;
};

/**
 * Returns the objective description and if it exists based on the user type (used in HomeModuleList, infoPanels for students & teachers)
 */
export const getItemDescription = (
    item: Module | Objective | Activity,
    userType: "student" | "teacher"
): string => {
    return item.descriptions
        ? item.descriptions[userType].$html
        : item.description
        ? item.description.$html
        : "";
};

export const getResourceIndex = (
    id: string,
    pool: MinimalDataItem[] | string[]
) => {
    if (typeof pool[0] === "string")
        return (pool as string[]).findIndex((itemId) => itemId === id);
    return (pool as MinimalDataItem[]).findIndex((item) => item.id === id);
};

/**
 * It assumes that the exercise is only in one activity/objective/module, which
 * might not be the case inn the future.
 */
export const assumeActivityFromExerciseId = (
    exerciseId: string,
    data: Data
) => {
    const activity = data.activities.find((activity) =>
        activity.exerciseIds.includes(exerciseId)
    );
    if (!activity) throw new ActivityNotFoundError();
    return activity;
};
export const assumeObjectiveFromExerciseId = (
    exerciseId: string,
    data: Data
) => {
    const activity = assumeActivityFromExerciseId(exerciseId, data);
    const objective = data.objectives.find((objective) =>
        objective.activityIds.includes(activity.id)
    );
    if (!objective)
        throw new ObjectiveNotFoundError(
            `No objective containing activity ${activity.id}`
        );
    return objective;
};

export const getExerciseHierarchyIfExisting = (
    exerciseId: string,
    data: Data,
    exercises: Exercise[]
): Partial<Hierarchy> => {
    const exercise = exercises.find((exercise) => exercise.id === exerciseId);
    if (!exercise) return {};
    const activity = data.activities.find((activity) =>
        activity.exerciseIds.includes(exerciseId)
    );
    if (!activity) return { exercise };

    const objective = data.objectives.find((objective) =>
        objective.activityIds.includes(activity.id)
    );
    if (!objective) return { exercise, activity };

    const mod = data.modules.find((mod) =>
        mod.objectiveIds.includes(objective.id)
    );
    if (!mod) return { exercise, activity, objective };
    return {
        exercise,
        activity,
        objective,
        module: mod,
    };
};

export const areActivitiesInSameObjective = (
    actId1: string,
    actId2: string,
    data: Data
): boolean => {
    return data.objectives.some(
        (objective) =>
            objective.activityIds.includes(actId1) &&
            objective.activityIds.includes(actId2)
    );
};

export const objectiveNumberSort = (
    data: Data
): Parameters<typeof Array.prototype.sort> => [
    (objA: Objective, objB: Objective) => {
        const module = data.modules.find(
            (mod) =>
                mod.objectiveIds.includes(objA.id) &&
                mod.objectiveIds.includes(objB.id)
        );
        if (!module) throw new Error("Objectives are not in the same module");
        return (
            module.objectiveIds.findIndex((objId) => objId === objA.id) -
            module.objectiveIds.findIndex((objId) => objId === objB.id)
        );
    },
];

export const objectiveInModuleFilter: (
    moduleId: string,
    data: Data
) => Parameters<Array<string>["filter"]>[0] =
    (moduleId, data) => (objectiveId) =>
        getModuleById(moduleId, data).objectiveIds.includes(objectiveId);

export const activityInModuleFilter: (
    moduleId: string,
    data: Data
) => Parameters<Array<string>["filter"]>[0] =
    (moduleId, data) => (activityId) => {
        const objectives = getModuleById(moduleId, data).objectiveIds.map(
            (objId) => getObjectiveById(objId, data)
        );
        return objectives.some((objective) =>
            objective.activityIds.includes(activityId)
        );
    };
/**
 * Display in the console the hierarchy & result of the current exercise (feature for POs & AI team)
 * For the soloAI mode
 */
export const logExerciseDetails = (
    result: PartialExerciseResult<any>,
    resourceSeries: Pick<ResourceSeries, "hierarchy" | "resources">,
    data: Data,
    initialTest: PerModule<any>
) => {
    const learningItem = getLearningItem(resourceSeries);
    if (!learningItem || !resourceSeries.hierarchy) return;

    //Module
    let moduleIndex = getResourceIndex(
        resourceSeries.hierarchy[3],
        data.modules
    );

    //Objective
    let isObjectiveTest = false;

    const mod = getModuleById(resourceSeries.hierarchy[3], data);

    let objectiveTestId = mod.objectiveIds.find(
        initialTestFilter(initialTest, "isInitialTest")
    );

    if (objectiveTestId && resourceSeries.hierarchy[2] === objectiveTestId) {
        isObjectiveTest = true;
    }
    let objectiveIndex = getResourceIndex(
        resourceSeries.hierarchy[2],
        mod.objectiveIds
    );
    const objective = getObjectiveById(resourceSeries.hierarchy[2], data);
    //Activity
    let activityIndex = getResourceIndex(
        resourceSeries.hierarchy[1],
        objective.activityIds
    );
    const activity = getActivityById(resourceSeries.hierarchy[1], data);

    //Exercise
    let exerciseIndex = getResourceIndex(learningItem.id, activity.exerciseIds);

    let results = {
        module: `Module - ${moduleIndex + 1}`,
        objective: `Objective - ${
            isObjectiveTest
                ? "initial"
                : objectiveTestId
                ? objectiveIndex
                : objectiveIndex + 1
        }`,
        activity: `Activity - ${activityIndex + 1}`,
        exercise: `Exercise - ${exerciseIndex + 1}`,
        isCorrect: result.correct,
    };

    console.table(results);
};

export const getTotalNumberOfExercises = (
    activities: ZPDESActivityDiagnosis[]
): number => {
    return activities.reduce((total, activity) => {
        return total + activity.exercises.length;
    }, 0);
};

export const meanSuccess = (activities: ZPDESActivityDiagnosis[]): number => {
    const totalScore = activities.reduce((totalScore, activity) => {
        return (
            totalScore +
            activity.exercises.reduce((activityTotalScore, exercise) => {
                return activityTotalScore + exercise.success.current;
            }, 0)
        );
    }, 0);

    return totalScore / getTotalNumberOfExercises(activities);
};

export const getNumberOfCompletedExercises = (
    activityDiagnosis: ZPDESActivityDiagnosis[]
) => {
    let completedExercises = 0;

    activityDiagnosis.forEach((activity) => {
        completedExercises += activity.exercises.length;
    });

    return completedExercises;
};

export const getStudent = (studentId: string, classrooms: Group[]): Student => {
    let result: Student | undefined = undefined;
    for (const classroom of classrooms) {
        for (const student of classroom.students) {
            if (student.id === studentId) {
                result = student;
                break;
            }
        }
        if (result) break;
    }
    if (!result) throw new Error("Student not found");
    return result;
};

/** Returns the status of a module's preliminatory test */
export const getPreliminatoryTestStatus = (
    moduleStatements: Statement[],
    isInitialTest: boolean
) => {
    if (moduleStatements.length && isInitialTest) return "inProgress";
    if (moduleStatements.length && !isInitialTest) return "finished";

    return "unopened";
};

export const removeNonExistantModules = (
    dashboard: Dashboard,
    data: Data
): Dashboard => {
    const moduleIds = data.modules.map((mod) => mod.id);
    return {
        classrooms: dashboard.classrooms.map((classroom) => ({
            ...classroom,
            modulesList: classroom.modulesList.filter((mod) =>
                moduleIds.includes(mod.id)
            ),
        })),
        clustering: !dashboard.clustering
            ? undefined
            : mapObjectValues(dashboard.clustering, (modules) =>
                  filterObjectKeys(modules, ([moduleId]) =>
                      moduleIds.includes(moduleId)
                  )
              ),
    };
};

export const filterModulesBySubject = (
    subject: Subject,
    data: Data,
    mappingTree?: MappingNode
): Module[] =>
    data.modules.filter((mod) => {
        const moduleSubjects = getModuleSubjects(mod, data, mappingTree);
        return moduleSubjects.some(
            (moduleSubject) => moduleSubject.id === subject.id
        );
    });

export const filterObjectivesInModules = (
    modules: Module[],
    objectives: Objective[]
): Objective[] =>
    modules.flatMap((mod) =>
        objectives.filter((objective) =>
            mod.objectiveIds.includes(objective.id)
        )
    );

export const filterActivitiesInObjectives = (
    objectives: Objective[],
    activities: Activity[]
): Activity[] =>
    objectives.flatMap((objective) =>
        activities.filter((activity) =>
            objective.activityIds.includes(activity.id)
        )
    );

export const filterExercisesInActivities = <T extends { id: string }>(
    activities: Activity[],
    exercises: T[]
): T[] =>
    activities.flatMap((activity) =>
        exercises.filter((exercise) =>
            activity.exerciseIds.includes(exercise.id)
        )
    );

export const getModuleSubjects = (
    mod: Module,
    data: Data,
    mappingTree?: MappingNode
): Subject[] => {
    return getSetSubjects(mod.objectiveIds, data, mappingTree);
};

export const getPlaylistSubjects = (
    playlist: Playlist<PlaylistItem>,
    resources: { data: Data; collections: (Tuto | Workshop)[] },
    mappingTree?: MappingNode
) => {
    const playlistSubjects = new Set([PLACEHOLDER_SUBJECT]);
    if (!mappingTree) return Array.from(playlistSubjects);

    const subjects = mappingTreeToSubjects(mappingTree);

    const tags = playlist.learning_items.flatMap((item) => {
        const path = ResourcePath.fromLearningItem(item);
        if (path instanceof CollectionResourcePath) {
            const set = resources.collections.find(
                (set) => set.id === path.setId
            );
            return set?.mapping_nodes;
        } else {
            return getObjectiveById(path.objectiveId, resources.data)
                .mapping_nodes;
        }
    });

    subjects.forEach((subject) => {
        if (subject.tags.some((tag) => tags.includes(tag)))
            playlistSubjects.add(subject);
    });

    return Array.from(playlistSubjects);
};

const getSetSubjects = (
    setObjectives: string[],
    data: Data,
    mappingTree?: MappingNode
) => {
    const setSubjects = new Set([PLACEHOLDER_SUBJECT]);
    if (!mappingTree) return Array.from(setSubjects);

    const subjects = mappingTreeToSubjects(mappingTree);

    setObjectives.forEach((objectiveId) => {
        const objective = getObjectiveById(objectiveId, data);
        const objectiveSubjects = getObjectiveSubjects(objective, subjects);
        objectiveSubjects.forEach((subject) => setSubjects.add(subject));
    });
    return Array.from(setSubjects);
};

const getObjectiveSubjects = (
    objective: Objective,
    subjects: Subject[]
): Subject[] => {
    if (!objective.mapping_nodes || objective.mapping_nodes.length === 0)
        return [];
    return subjects.filter((subject) =>
        objective.mapping_nodes!.some((nodeId) => subject.tags.includes(nodeId))
    );
};

export const shouldChooseSubject = (
    features: FeatureFlags,
    activeSubject: string | undefined
) => {
    if (features.requireMapping && !activeSubject) return true;
    return false;
};

/** Returns the module and objective ids currently stored in the cache */
export const getCachedModulesAndObjectivesIds = (
    modules: Module[],
    cachedIds: string[]
) => {
    return {
        moduleIdsInCache: modules
            .map(({ id }) => id)
            .filter((id) => cachedIds.includes(id)),
        objectiveIdsInCache: modules
            .flatMap(({ objectiveIds }) => objectiveIds)
            .filter((id) => cachedIds.includes(id)),
    };
};

/** Returns a boolean which allows to check if a given id (for a module or an objective) has been cached or not */
export type CachedModuleAndObjectiveIds = {
    moduleIdsInCache: string[];
    objectiveIdsInCache: string[];
};

export const hasCachedId = (
    id: string,
    cachedIds: CachedModuleAndObjectiveIds,
    type: "module" | "objective"
) => {
    if (type === "module") {
        return cachedIds?.moduleIdsInCache.includes(id);
    } else if (type === "objective") {
        return cachedIds?.objectiveIdsInCache.includes(id);
    } else {
        return false;
    }
};

/**
 * https://evidencebprod.atlassian.net/browse/ATH-518
 * Classes from past years were displayed on the dashboard (with 0 students).
 * This fix filters the classes to display when they come from the provider GAR, based on their external_id which contains the school year they were added
 * (ie. external_id: "0750714U-1ERE05##1ERE05-2022-2023").
 * This is a temporary fix that will be replaced by a cleaner fix in the backend side at a later date.
 * Based on the french school year
 */
export const tempGarFilter = (external_id: string, appProvider: string) => {
    if (!appProvider.includes("-GAR")) return true;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let currentSchoolYear: string;
    if (currentMonth < 8) {
        currentSchoolYear = `${currentYear - 1}-${currentYear}`;
    } else {
        currentSchoolYear = `${currentYear}-${currentYear + 1}`;
    }

    const externalIdSchoolYear = external_id.slice(-9);
    if (externalIdSchoolYear === currentSchoolYear) {
        return true;
    }
    return false;
};
